<template>
  <div>
    <b-row>
      <b-col md="3">
        <FormulateInput
          id="tax-ipi-st-ipi-output"
          v-model="taxClassification.stIpiOutput"
          name="stIpiOutput"
          type="vue-select"
          :label="$t('Cst Ipi Saída')"
          :placeholder="$t('Selecione')"
          :options="getComboCstIpiOutput"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="tax-ipi-st-ipi-input"
          v-model="taxClassification.stIpiInput"
          name="stIpiInput"
          type="vue-select"
          :label="$t('Cst Ipi Entrada')"
          :placeholder="$t('Selecione')"
          :options="getComboCstIpiInput"
          validation="required"
          class="required"
        />
      </b-col>
      <b-col
        v-if="taxClassification.stIpiOutput === '50' || taxClassification.stIpiOutput === '99'"
        md="3"
      >
        <FormulateInput
          id="tax-ipi-ipi-percent"
          v-model="taxClassification.ipiPercent"
          type="text-percetage"
          validation="required|number"
          name="ipiPercent"
          :label="$t('Alíquota Ipi')"
          class="required"
          currency="%"
          :precision="4"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="tax-ipi-ipi-legal-framework-code"
          v-model="taxClassification.ipiLegalFrameworkCode"
          type="text"
          name="ipiLegalFrameworkCode"
          :label="$t('Código de Enquadramento Legal')"
          maxlength="3"
          validation="required"
          class="required"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'TaxClassificationIpiForm',

  components: { BCol, BRow },

  computed: {
    ...mapState('pages/invoice/taxClassifications/taxClassificationMaintain', [
      'taxClassification',
    ]),
    ...mapGetters('pages/invoice/taxClassifications', [
      'getComboCstIpiInput',
      'getComboCstIpiOutput',
    ]),
  },

  mounted() {
    this.fetchTaxClassificationCombos()
  },

  methods: {
    ...mapActions('pages/invoice/taxClassifications', ['fetchTaxClassificationCombos']),
  },
}
</script>

<style></style>
