<template>
  <section>
    <FormulateForm
      ref="formTaxClassification"
      name="formTaxClassification"
      :model="taxClassification"
      @submit="saveTaxClassification"
    >
      <b-card-actions
        action-collapse
        :title="$t('Informações Fiscais')"
      >
        <tax-classification-form />
      </b-card-actions>
      <b-card-actions
        action-collapse
        :title="$t('Icms')"
      >
        <tax-classification-icms-form />
      </b-card-actions>
      <b-card-actions
        action-collapse
        :title="$t('Ipi')"
      >
        <tax-classification-ipi-form />
      </b-card-actions>
      <b-card-actions
        action-collapse
        :title="$t('Pis e Cofins')"
      >
        <tax-classification-pis-cofins-form />
      </b-card-actions>

      <b-row class="bottom-form-submit">
        <b-col cols="12">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click.prevent="cancelHandle"
          >
            {{ $t('Cancelar') }}
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            :disabled="busy"
          >
            <b-spinner
              v-if="busy"
              small
              style="margin-bottom: 3px"
            />
            {{ $t('Salvar') }}
          </b-button>
        </b-col>
      </b-row>
    </FormulateForm>
  </section>
</template>

<script>
import { BRow, BCol, BButton, BSpinner } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapState } from 'vuex'
import { alerts } from '@/mixins'
import TaxClassificationForm from './components/TaxClassificationForm.vue'
import TaxClassificationIcmsForm from './components/TaxClassificationIcmsForm.vue'
import TaxClassificationIpiForm from './components/TaxClassificationIpiForm.vue'
import TaxClassificationPisCofinsForm from './components/TaxClassificationPisCofinsForm.vue'

const initialSidebarForm = () => ({
  name: '',
})

export default {
  name: 'TaxClassificationMaintain',
  components: {
    BCardActions,
    BRow,
    BCol,
    BButton,
    BSpinner,
    TaxClassificationForm,
    TaxClassificationIcmsForm,
    TaxClassificationIpiForm,
    TaxClassificationPisCofinsForm,
  },

  mixins: [alerts],

  data() {
    return {
      showHelperSidebar: false,
      saving: false,
      busy: false,
      isEdit: !!this.$router.currentRoute.params.ncm,
      sidebarForm: initialSidebarForm(),
      formState: this.$store.state.pages?.invoice?.parameters?.taxClassificationMaintain?.formState,
    }
  },

  computed: {
    ...mapState('pages/invoice/taxClassifications/taxClassificationMaintain', [
      'taxClassification',
      'helperSidebarTypes',
      'helperSidebarState',
    ]),
  },

  async mounted() {
    try {
      if (this.isEdit) {
        const response = await this.$http.get(`/api/tax/tax-classification`, {
          params: {
            ncm: this.$router.currentRoute.params.ncm,
            originState: this.$router.currentRoute.params.originState,
            operationType: this.$router.currentRoute.params.operationType,
            destinationState: this.$router.currentRoute.params.destinationState,
          },
        })
        this.setTaxClassification(response.data)
      } else {
        this.cleanState()
      }
      this.showSidebar = true
    } catch (error) {
      this.showGenericError({ error })
    }
  },

  methods: {
    ...mapActions('pages/invoice/taxClassifications/taxClassificationMaintain', [
      'setHelperSidebarState',
      'cleanState',
      'setTaxClassification',
    ]),

    showHelperSideBar(type) {
      this.setHelperSidebarState(type)
      this.showHelperSidebar = true
    },

    async saveTaxClassification(data) {
      try {
        this.saving = true

        const body = {
          ...data,
          fcpPercent: data.fcpPercent || 0,
          fcpStPercent: data.fcpStPercent || 0,
          destinationState: data.destinationState || '',
        }
        await this.$http({
          url: '/api/tax/tax-classifications',
          method: this.isEdit ? 'PUT' : 'POST',
          data: body,
        })
        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })
        this.showSidebar = false
        this.$router.back()
        await this.$store.dispatch('pages/invoice/taxClassifications/fetchTaxClassifications')
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    cancelHandle() {
      this.cleanState()
      this.$router.back()
    },
  },
}
</script>
