var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-pis-cofins-st-pis-output",
                  name: "stPisOutput",
                  type: "vue-select",
                  label: _vm.$t("Cst Pis Saída"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboCstPisCofinsOutput,
                  validation: "required",
                },
                model: {
                  value: _vm.taxClassification.stPisOutput,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "stPisOutput", $$v)
                  },
                  expression: "taxClassification.stPisOutput",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-pis-cofins-st-pis-input",
                  name: "stPisInput",
                  type: "vue-select",
                  label: _vm.$t("Cst Pis Entrada"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboCstPisCofinsInput,
                  validation: "required",
                },
                model: {
                  value: _vm.taxClassification.stPisInput,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "stPisInput", $$v)
                  },
                  expression: "taxClassification.stPisInput",
                },
              }),
            ],
            1
          ),
          _vm.verifyIsTaxable()
            ? _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "tax-pis-cofins-pis-percent",
                      type: "text-percetage",
                      validation: "required|number",
                      name: "pisPercent",
                      label: _vm.$t("Alíquota Pis"),
                      currency: "%",
                      precision: 4,
                    },
                    model: {
                      value: _vm.taxClassification.pisPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.taxClassification, "pisPercent", $$v)
                      },
                      expression: "taxClassification.pisPercent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-pis-cofins-st-cofins-output",
                  name: "stCofinsOutput",
                  type: "vue-select",
                  label: _vm.$t("Cst Cofins Saída"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboCstPisCofinsOutput,
                  validation: "required",
                },
                model: {
                  value: _vm.taxClassification.stCofinsOutput,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "stCofinsOutput", $$v)
                  },
                  expression: "taxClassification.stCofinsOutput",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-pis-cofins-st-cofins-input",
                  name: "stCofinsInput",
                  type: "vue-select",
                  label: _vm.$t("Cst Cofins Entrada"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboCstPisCofinsInput,
                  validation: "required",
                },
                model: {
                  value: _vm.taxClassification.stCofinsInput,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "stCofinsInput", $$v)
                  },
                  expression: "taxClassification.stCofinsInput",
                },
              }),
            ],
            1
          ),
          _vm.verifyIsTaxable()
            ? _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "tax-pis-cofins-cofins-percent",
                      type: "text-percetage",
                      validation: "required|number",
                      name: "cofinsPercent",
                      label: _vm.$t("Alíquota Cofins"),
                      currency: "%",
                      precision: 4,
                    },
                    model: {
                      value: _vm.taxClassification.cofinsPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.taxClassification, "cofinsPercent", $$v)
                      },
                      expression: "taxClassification.cofinsPercent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }