<template>
  <div>
    <b-row>
      <b-col md="4">
        <FormulateInput
          id="tax-pis-cofins-st-pis-output"
          v-model="taxClassification.stPisOutput"
          name="stPisOutput"
          type="vue-select"
          :label="$t('Cst Pis Saída')"
          :placeholder="$t('Selecione')"
          :options="getComboCstPisCofinsOutput"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="4">
        <FormulateInput
          id="tax-pis-cofins-st-pis-input"
          v-model="taxClassification.stPisInput"
          name="stPisInput"
          type="vue-select"
          :label="$t('Cst Pis Entrada')"
          :placeholder="$t('Selecione')"
          :options="getComboCstPisCofinsInput"
          validation="required"
          class="required"
        />
      </b-col>
      <b-col
        v-if="verifyIsTaxable()"
        md="4"
      >
        <FormulateInput
          id="tax-pis-cofins-pis-percent"
          v-model="taxClassification.pisPercent"
          type="text-percetage"
          validation="required|number"
          name="pisPercent"
          :label="$t('Alíquota Pis')"
          class="required"
          currency="%"
          :precision="4"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <FormulateInput
          id="tax-pis-cofins-st-cofins-output"
          v-model="taxClassification.stCofinsOutput"
          name="stCofinsOutput"
          type="vue-select"
          :label="$t('Cst Cofins Saída')"
          :placeholder="$t('Selecione')"
          :options="getComboCstPisCofinsOutput"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="4">
        <FormulateInput
          id="tax-pis-cofins-st-cofins-input"
          v-model="taxClassification.stCofinsInput"
          name="stCofinsInput"
          type="vue-select"
          :label="$t('Cst Cofins Entrada')"
          :placeholder="$t('Selecione')"
          :options="getComboCstPisCofinsInput"
          validation="required"
          class="required"
        />
      </b-col>
      <b-col
        v-if="verifyIsTaxable()"
        md="4"
      >
        <FormulateInput
          id="tax-pis-cofins-cofins-percent"
          v-model="taxClassification.cofinsPercent"
          type="text-percetage"
          validation="required|number"
          name="cofinsPercent"
          :label="$t('Alíquota Cofins')"
          class="required"
          currency="%"
          :precision="4"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'TaxClassificationIpiForm',

  components: { BCol, BRow },

  computed: {
    ...mapState('pages/invoice/taxClassifications/taxClassificationMaintain', [
      'taxClassification',
    ]),
    ...mapGetters('pages/invoice/taxClassifications', [
      'getComboCstPisCofinsInput',
      'getComboCstPisCofinsOutput',
    ]),
  },

  mounted() {
    this.fetchTaxClassificationCombos()
  },

  methods: {
    ...mapActions('pages/invoice/taxClassifications', ['fetchTaxClassificationCombos']),

    verifyIsTaxable() {
      if (
        this.taxClassification.stPisOutput === '01' ||
        this.taxClassification.stPisOutput === '02' ||
        this.taxClassification.stPisOutput === '03' ||
        this.taxClassification.stPisOutput === '49' ||
        this.taxClassification.stPisOutput === '99'
      ) {
        return true
      }
      return false
    },
  },
}
</script>

<style></style>
