<template>
  <div>
    <b-row>
      <b-col md="6">
        <FormulateInput
          id="tax-classification-ncm"
          v-model="taxClassification.ncm"
          type="select-searchable"
          name="ncm"
          :label="$t('NCM')"
          :placeholder="$t('Digite para pesquisar')"
          :options="getComboNcms"
          autofocus
          class="required"
          validation="required"
          :disabled="isEdit"
          @fetchSearch="ncmSearch"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="tax-classification-origin-state"
          v-model="taxClassification.originState"
          name="originState"
          type="vue-select"
          :label="$t('Estado de Origem')"
          :placeholder="$t('Selecione')"
          :options="getComboStates"
          class="required"
          validation="required"
          :disabled="isEdit"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="tax-classification-operation-type"
          v-model="taxClassification.operationType"
          name="operationType"
          type="vue-select"
          :label="$t('Tipo de Operação')"
          :placeholder="$t('Selecione')"
          :options="getComboOperationTypes"
          class="required"
          validation="required"
          :disabled="isEdit"
        />
      </b-col>
      <b-col
        v-if="taxClassification.operationType === 'INTERSTATE'"
        md="2"
      >
        <FormulateInput
          id="tax-classification-destination-state"
          v-model="taxClassification.destinationState"
          name="destinationState"
          type="vue-select"
          :label="$t('Estado de Destino')"
          :placeholder="$t('Selecione')"
          :options="getComboStatesWithAll"
          class="required"
          validation="required"
          :disabled="isEdit"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <FormulateInput
          id="tax-classification-cfop-output"
          v-model="taxClassification.cfopOutput"
          name="cfopOutput"
          type="vue-select"
          :label="$t('Cfop Saída')"
          :placeholder="$t('Selecione')"
          :options="getComboCfopOutput"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="4">
        <FormulateInput
          id="tax-classification-cfop-input"
          v-model="taxClassification.cfopInput"
          name="cfopInput"
          type="vue-select"
          :label="$t('Cfop Entrada')"
          :placeholder="$t('Selecione')"
          :options="getComboCfopInput"
          class="required"
          validation="required"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'TaxClassificationForm',

  components: { BCol, BRow },

  data() {
    return {
      isEdit: !!this.$router.currentRoute.params.ncm,
    }
  },

  computed: {
    ...mapState('pages/invoice/taxClassifications/taxClassificationMaintain', [
      'taxClassification',
    ]),
    ...mapGetters('pages/invoice/taxClassifications', [
      'getComboOperationTypes',
      'getComboCfopInput',
      'getComboCfopOutput',
    ]),
    ...mapGetters('common/states', ['getComboStates', 'getComboStatesWithAll']),
    ...mapGetters('common/ncms', ['getComboNcms']),
  },

  async mounted() {
    this.fetchTaxClassificationCombos()
    this.fetchStates()
    if (this.isEdit) {
      await this.fetchNcms({ itemSearch: this.$router.currentRoute.params.ncm })
    }
  },

  methods: {
    ...mapActions('pages/invoice/taxClassifications', ['fetchTaxClassificationCombos']),
    ...mapActions('common/states', ['fetchStates']),
    ...mapActions('common/ncms', ['fetchNcms']),

    async ncmSearch(loading, search) {
      try {
        loading(true)
        await this.fetchNcms({ itemSearch: search })
      } catch (e) {
        this.showError({
          message: this.$t('Não foi possível fazer a pesquisa'),
        })
      } finally {
        loading(false)
      }
    },
  },
}
</script>

<style></style>
