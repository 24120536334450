var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-ipi-st-ipi-output",
                  name: "stIpiOutput",
                  type: "vue-select",
                  label: _vm.$t("Cst Ipi Saída"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboCstIpiOutput,
                  validation: "required",
                },
                model: {
                  value: _vm.taxClassification.stIpiOutput,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "stIpiOutput", $$v)
                  },
                  expression: "taxClassification.stIpiOutput",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-ipi-st-ipi-input",
                  name: "stIpiInput",
                  type: "vue-select",
                  label: _vm.$t("Cst Ipi Entrada"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboCstIpiInput,
                  validation: "required",
                },
                model: {
                  value: _vm.taxClassification.stIpiInput,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "stIpiInput", $$v)
                  },
                  expression: "taxClassification.stIpiInput",
                },
              }),
            ],
            1
          ),
          _vm.taxClassification.stIpiOutput === "50" ||
          _vm.taxClassification.stIpiOutput === "99"
            ? _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "tax-ipi-ipi-percent",
                      type: "text-percetage",
                      validation: "required|number",
                      name: "ipiPercent",
                      label: _vm.$t("Alíquota Ipi"),
                      currency: "%",
                      precision: 4,
                    },
                    model: {
                      value: _vm.taxClassification.ipiPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.taxClassification, "ipiPercent", $$v)
                      },
                      expression: "taxClassification.ipiPercent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-ipi-ipi-legal-framework-code",
                  type: "text",
                  name: "ipiLegalFrameworkCode",
                  label: _vm.$t("Código de Enquadramento Legal"),
                  maxlength: "3",
                  validation: "required",
                },
                model: {
                  value: _vm.taxClassification.ipiLegalFrameworkCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.taxClassification,
                      "ipiLegalFrameworkCode",
                      $$v
                    )
                  },
                  expression: "taxClassification.ipiLegalFrameworkCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }