<template>
  <div>
    <b-row>
      <b-col md="6">
        <FormulateInput
          id="tax-icms-st-icms"
          v-model="taxClassification.stIcms"
          name="stIcms"
          type="vue-select"
          :label="$t('Cst Icms')"
          :placeholder="$t('Selecione')"
          :options="getComboCstIcms"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="6">
        <FormulateInput
          id="tax-icms-csosn-icms"
          v-model="taxClassification.csosnIcms"
          name="csosnIcms"
          type="vue-select"
          :label="$t('Csosn Icms')"
          :placeholder="$t('Selecione')"
          :options="getComboCsosnIcms"
        />
      </b-col>
      <b-col
        v-if="verifyIsTaxable()"
        md="4"
      >
        <FormulateInput
          id="tax-icms-icms-percent"
          v-model="taxClassification.icmsPercent"
          type="text-percetage"
          validation="required|number|min:0.01"
          name="icmsPercent"
          currency="%"
          :precision="4"
          :label="$t('Alíquota Icms')"
          class="required"
        />
      </b-col>
      <b-col
        v-if="verifyIsTaxable()"
        md="4"
      >
        <FormulateInput
          id="tax-icms-fcp-percent"
          v-model="taxClassification.fcpPercent"
          type="text-percetage"
          name="fcpPercent"
          :label="$t('Alíquota Fundo de Combate à Pobreza')"
          validation="optional|number"
          currency="%"
          :precision="4"
        />
      </b-col>
      <b-col
        v-if="
          taxClassification.stIcms === '20' ||
            taxClassification.stIcms === '70' ||
            taxClassification.stIcms === '90'
        "
        md="4"
      >
        <FormulateInput
          id="tax-icms-icmsBaseReduction-percent"
          v-model="taxClassification.icmsBaseReductionPercent"
          type="text-percetage"
          name="icmsBaseReductionPercent"
          :label="$t('Redução Base Icms')"
          validation="number"
          currency="%"
          :precision="4"
        />
      </b-col>
      <b-col
        v-if="taxClassification.stIcms === '20'"
        md="4"
      >
        <FormulateInput
          id="tax-icms-reliefReason-percent"
          v-model="taxClassification.icmsTaxReliefReason"
          name="icmsTaxReliefReason"
          type="vue-select"
          :label="$t('Motivo da desoneração do ICMS, caso exigido pelo estado')"
          :placeholder="$t('Selecione')"
          :options="getComboIcmsTaxReliefReason"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="tax-icms-icms-benefit-code"
          v-model="taxClassification.benefitCode"
          type="text"
          name="benefitCode"
          :label="$t('Código do Beneficio Fiscal')"
        />
      </b-col>
    </b-row>
    <b-row v-if="verifyIsTaxableSt()">
      <b-col md="2">
        <FormulateInput
          id="tax-icms-iva"
          v-model="taxClassification.iva"
          type="text-percetage"
          name="iva"
          :label="$t('Iva')"
          class="required"
          validation="required|number"
          currency="%"
          :precision="4"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="tax-icms-icms-st-percent"
          v-model="taxClassification.icmsStPercent"
          type="text-percetage"
          name="icmsStPercent"
          :label="$t('Alíquota Icms ST')"
          class="required"
          validation="required|number"
          currency="%"
          :precision="4"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="tax-icms-fcp-st-percent"
          v-model="taxClassification.fcpStPercent"
          type="text-percetage"
          name="fcpStPercent"
          :label="$t('Alíquota Fundo de Combate à Pobreza ST')"
          validation="optional|number"
          currency="%"
          :precision="4"
        />
      </b-col>
      <b-col
        v-if="taxClassification.stIcms === '70' || taxClassification.stIcms === '90'"
        md="2"
      >
        <FormulateInput
          id="tax-icms-icms-st-base-reduction-percent"
          v-model="taxClassification.icmsStBaseReductionPercent"
          type="text-percetage"
          name="icmsStBaseReductionPercent"
          :label="$t('Redução Base Icms ST')"
          class="required"
          validation="required|number"
          currency="%"
          :precision="4"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'TaxClassificationIcmsForm',

  components: { BCol, BRow },

  computed: {
    ...mapState('pages/invoice/taxClassifications/taxClassificationMaintain', [
      'taxClassification',
    ]),
    ...mapGetters('pages/invoice/taxClassifications', ['getCstIcms', 'getCsosnIcms']),
    getComboIcmsTaxReliefReason() {
      // referência: https://atendimento.tecnospeed.com.br/hc/pt-br/articles/360014970574-Regra-Tributaria-motDesICMS-motDesICMSST-Motivo-da-desonera%C3%A7%C3%A3o-do-ICMS-ICMS-ST-da-NF-e
      const allValues = [
        { label: '1 - Táxi', value: '1' },
        { label: '3 - Produtor Agropecuário', value: '3' },
        { label: '4 - Frotista/Locadora', value: '4' },
        { label: '5 - Diplomático/Consular', value: '5' },
        {
          label:
            '6 - Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio (Resolução 714/88 e 790/94 - CONTRAN e suas alterações)',
          value: '6',
        },
        { label: '7 - SUFRAMA', value: '7' },
        { label: '8 - Venda a Órgãos Públicos', value: '8' },
        { label: '9 - Outros', value: '9' },
        { label: '10 - Deficiente Condutor (Convênio ICMS 38/12)', value: '10' },
        { label: '11 - Deficiente Não Condutor (Convênio ICMS 38/12)', value: '11' },
        { label: '12 - Órgão de fomento e desenvolvimento agropecuário', value: '12' },
        { label: '16 - Olimpíadas Rio 2016', value: '16' },
        { label: '90 - Solicitado pelo Fisco', value: '90' },
      ]

      switch (this.taxClassification.stIcms) {
        case '10':
        case '20':
        case '70':
        case '90':
          return allValues.filter(c => ['3', '9', '12'].includes(c.value))
        case '30':
          return allValues.filter(c => ['6', '7', '9'].includes(c.value))
        default:
          return allValues
      }
    },
    getComboCsosnIcms() {
      return this.getCsosnIcms.map(c => ({
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
    getComboCstIcms() {
      return this.getCstIcms.map(c => ({
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
  },

  mounted() {
    this.fetchTaxClassificationCombos()
  },

  methods: {
    ...mapActions('pages/invoice/taxClassifications', ['fetchTaxClassificationCombos']),

    verifyIsTaxable() {
      for (let i = 0; i < this.getCstIcms.length; i += 1) {
        if (this.getCstIcms[i].code === this.taxClassification.stIcms) {
          if (
            this.getCstIcms[i].taxRateType === 'NORMAL_TAXABLE' ||
            this.getCstIcms[i].taxRateType === 'TAXABLE_WITH_ST' ||
            this.getCstIcms[i].taxRateType === 'NORMAL_TAXABLE_WITH_REDUCTION' ||
            this.getCstIcms[i].taxRateType === 'TAXABLE_WITH_ST_AND_REDUCTION' ||
            this.getCstIcms[i].taxRateType === 'OTHER'
          ) {
            return true
          }
        }
      }
      return false
    },

    verifyIsTaxableSt() {
      for (let i = 0; i < this.getCstIcms.length; i += 1) {
        if (this.getCstIcms[i].code === this.taxClassification.stIcms) {
          if (
            this.getCstIcms[i].taxRateType === 'TAXABLE_WITH_ST' ||
            this.getCstIcms[i].taxRateType === 'TAXABLE_WITH_ST_AND_REDUCTION' ||
            this.getCstIcms[i].taxRateType === 'OTHER'
          ) {
            return true
          }
        }
      }
      return false
    },
  },
}
</script>

<style></style>
