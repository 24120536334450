var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-icms-st-icms",
                  name: "stIcms",
                  type: "vue-select",
                  label: _vm.$t("Cst Icms"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboCstIcms,
                  validation: "required",
                },
                model: {
                  value: _vm.taxClassification.stIcms,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "stIcms", $$v)
                  },
                  expression: "taxClassification.stIcms",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "tax-icms-csosn-icms",
                  name: "csosnIcms",
                  type: "vue-select",
                  label: _vm.$t("Csosn Icms"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboCsosnIcms,
                },
                model: {
                  value: _vm.taxClassification.csosnIcms,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "csosnIcms", $$v)
                  },
                  expression: "taxClassification.csosnIcms",
                },
              }),
            ],
            1
          ),
          _vm.verifyIsTaxable()
            ? _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "tax-icms-icms-percent",
                      type: "text-percetage",
                      validation: "required|number|min:0.01",
                      name: "icmsPercent",
                      currency: "%",
                      precision: 4,
                      label: _vm.$t("Alíquota Icms"),
                    },
                    model: {
                      value: _vm.taxClassification.icmsPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.taxClassification, "icmsPercent", $$v)
                      },
                      expression: "taxClassification.icmsPercent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.verifyIsTaxable()
            ? _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "tax-icms-fcp-percent",
                      type: "text-percetage",
                      name: "fcpPercent",
                      label: _vm.$t("Alíquota Fundo de Combate à Pobreza"),
                      validation: "optional|number",
                      currency: "%",
                      precision: 4,
                    },
                    model: {
                      value: _vm.taxClassification.fcpPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.taxClassification, "fcpPercent", $$v)
                      },
                      expression: "taxClassification.fcpPercent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taxClassification.stIcms === "20" ||
          _vm.taxClassification.stIcms === "70" ||
          _vm.taxClassification.stIcms === "90"
            ? _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "tax-icms-icmsBaseReduction-percent",
                      type: "text-percetage",
                      name: "icmsBaseReductionPercent",
                      label: _vm.$t("Redução Base Icms"),
                      validation: "number",
                      currency: "%",
                      precision: 4,
                    },
                    model: {
                      value: _vm.taxClassification.icmsBaseReductionPercent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.taxClassification,
                          "icmsBaseReductionPercent",
                          $$v
                        )
                      },
                      expression: "taxClassification.icmsBaseReductionPercent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.taxClassification.stIcms === "20"
            ? _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "tax-icms-reliefReason-percent",
                      name: "icmsTaxReliefReason",
                      type: "vue-select",
                      label: _vm.$t(
                        "Motivo da desoneração do ICMS, caso exigido pelo estado"
                      ),
                      placeholder: _vm.$t("Selecione"),
                      options: _vm.getComboIcmsTaxReliefReason,
                    },
                    model: {
                      value: _vm.taxClassification.icmsTaxReliefReason,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.taxClassification,
                          "icmsTaxReliefReason",
                          $$v
                        )
                      },
                      expression: "taxClassification.icmsTaxReliefReason",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "tax-icms-icms-benefit-code",
                  type: "text",
                  name: "benefitCode",
                  label: _vm.$t("Código do Beneficio Fiscal"),
                },
                model: {
                  value: _vm.taxClassification.benefitCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "benefitCode", $$v)
                  },
                  expression: "taxClassification.benefitCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.verifyIsTaxableSt()
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "tax-icms-iva",
                      type: "text-percetage",
                      name: "iva",
                      label: _vm.$t("Iva"),
                      validation: "required|number",
                      currency: "%",
                      precision: 4,
                    },
                    model: {
                      value: _vm.taxClassification.iva,
                      callback: function ($$v) {
                        _vm.$set(_vm.taxClassification, "iva", $$v)
                      },
                      expression: "taxClassification.iva",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "tax-icms-icms-st-percent",
                      type: "text-percetage",
                      name: "icmsStPercent",
                      label: _vm.$t("Alíquota Icms ST"),
                      validation: "required|number",
                      currency: "%",
                      precision: 4,
                    },
                    model: {
                      value: _vm.taxClassification.icmsStPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.taxClassification, "icmsStPercent", $$v)
                      },
                      expression: "taxClassification.icmsStPercent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "tax-icms-fcp-st-percent",
                      type: "text-percetage",
                      name: "fcpStPercent",
                      label: _vm.$t("Alíquota Fundo de Combate à Pobreza ST"),
                      validation: "optional|number",
                      currency: "%",
                      precision: 4,
                    },
                    model: {
                      value: _vm.taxClassification.fcpStPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.taxClassification, "fcpStPercent", $$v)
                      },
                      expression: "taxClassification.fcpStPercent",
                    },
                  }),
                ],
                1
              ),
              _vm.taxClassification.stIcms === "70" ||
              _vm.taxClassification.stIcms === "90"
                ? _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "tax-icms-icms-st-base-reduction-percent",
                          type: "text-percetage",
                          name: "icmsStBaseReductionPercent",
                          label: _vm.$t("Redução Base Icms ST"),
                          validation: "required|number",
                          currency: "%",
                          precision: 4,
                        },
                        model: {
                          value:
                            _vm.taxClassification.icmsStBaseReductionPercent,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.taxClassification,
                              "icmsStBaseReductionPercent",
                              $$v
                            )
                          },
                          expression:
                            "taxClassification.icmsStBaseReductionPercent",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }