var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-classification-ncm",
                  type: "select-searchable",
                  name: "ncm",
                  label: _vm.$t("NCM"),
                  placeholder: _vm.$t("Digite para pesquisar"),
                  options: _vm.getComboNcms,
                  autofocus: "",
                  validation: "required",
                  disabled: _vm.isEdit,
                },
                on: { fetchSearch: _vm.ncmSearch },
                model: {
                  value: _vm.taxClassification.ncm,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "ncm", $$v)
                  },
                  expression: "taxClassification.ncm",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-classification-origin-state",
                  name: "originState",
                  type: "vue-select",
                  label: _vm.$t("Estado de Origem"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboStates,
                  validation: "required",
                  disabled: _vm.isEdit,
                },
                model: {
                  value: _vm.taxClassification.originState,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "originState", $$v)
                  },
                  expression: "taxClassification.originState",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-classification-operation-type",
                  name: "operationType",
                  type: "vue-select",
                  label: _vm.$t("Tipo de Operação"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboOperationTypes,
                  validation: "required",
                  disabled: _vm.isEdit,
                },
                model: {
                  value: _vm.taxClassification.operationType,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "operationType", $$v)
                  },
                  expression: "taxClassification.operationType",
                },
              }),
            ],
            1
          ),
          _vm.taxClassification.operationType === "INTERSTATE"
            ? _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "tax-classification-destination-state",
                      name: "destinationState",
                      type: "vue-select",
                      label: _vm.$t("Estado de Destino"),
                      placeholder: _vm.$t("Selecione"),
                      options: _vm.getComboStatesWithAll,
                      validation: "required",
                      disabled: _vm.isEdit,
                    },
                    model: {
                      value: _vm.taxClassification.destinationState,
                      callback: function ($$v) {
                        _vm.$set(_vm.taxClassification, "destinationState", $$v)
                      },
                      expression: "taxClassification.destinationState",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-classification-cfop-output",
                  name: "cfopOutput",
                  type: "vue-select",
                  label: _vm.$t("Cfop Saída"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboCfopOutput,
                  validation: "required",
                },
                model: {
                  value: _vm.taxClassification.cfopOutput,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "cfopOutput", $$v)
                  },
                  expression: "taxClassification.cfopOutput",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "tax-classification-cfop-input",
                  name: "cfopInput",
                  type: "vue-select",
                  label: _vm.$t("Cfop Entrada"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboCfopInput,
                  validation: "required",
                },
                model: {
                  value: _vm.taxClassification.cfopInput,
                  callback: function ($$v) {
                    _vm.$set(_vm.taxClassification, "cfopInput", $$v)
                  },
                  expression: "taxClassification.cfopInput",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }