var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        {
          ref: "formTaxClassification",
          attrs: {
            name: "formTaxClassification",
            model: _vm.taxClassification,
          },
          on: { submit: _vm.saveTaxClassification },
        },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                "action-collapse": "",
                title: _vm.$t("Informações Fiscais"),
              },
            },
            [_c("tax-classification-form")],
            1
          ),
          _c(
            "b-card-actions",
            { attrs: { "action-collapse": "", title: _vm.$t("Icms") } },
            [_c("tax-classification-icms-form")],
            1
          ),
          _c(
            "b-card-actions",
            { attrs: { "action-collapse": "", title: _vm.$t("Ipi") } },
            [_c("tax-classification-ipi-form")],
            1
          ),
          _c(
            "b-card-actions",
            { attrs: { "action-collapse": "", title: _vm.$t("Pis e Cofins") } },
            [_c("tax-classification-pis-cofins-form")],
            1
          ),
          _c(
            "b-row",
            { staticClass: "bottom-form-submit" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-1",
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.cancelHandle($event)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Cancelar")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        type: "submit",
                        disabled: _vm.busy,
                      },
                    },
                    [
                      _vm.busy
                        ? _c("b-spinner", {
                            staticStyle: { "margin-bottom": "3px" },
                            attrs: { small: "" },
                          })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.$t("Salvar")) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }